//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LandingPagePopUp from "@/views/LandingPage/LandingPagePopUp.vue";
export default {
  name: "ForgotPassword",
  components: {
    LandingPagePopUp
  },
  methods: {
    previous() {
      this.$router.push({
        name: "sign-in"
      });
    },
    inputMnemonic() {
      this.$router.push({
        name: "input-mnemonic"
      });
    }
  }
};